import BaseModule from '@/store/baseFireVuex';
import axios from 'axios';
import Schema from 'async-validator';
import { storage } from '../../firebase';

/* eslint-disable max-classes-per-file */

const schema = {
  title: {
    type: 'string',
    required: true,
  },
  startAt: {
    type: 'date',
    required: true,
  },
  endAt: {
    type: 'date',
    required: true,
  },
  interfaceLang: {
    type: 'string',
    required: true,
  },

  shortLink: {
    type: 'string',
    required: true,
    transform(value) {
      if (!value) {
        return undefined;
      }
      return value.trim();
    },
  },
  type: {
    type: 'string',
    required: true,
    default: 'webinar',
  },
  /*
  status: {
    type: 'string',
    required: true,
    default: 'upcoming', upcoming, live, completed
  },
  */

  accountId: {
    type: 'string',
    required: true,
  },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'Event',
  getCollectionPath: (payload) => '/event',
  schema,
};

/* eslint-disable no-unused-vars */
class EventModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
    };
  }

  actions() {
    const getAndSet = async (context, assignId) => {
      await context.dispatch('get', { id: assignId });
      await context.dispatch('setCurrent', { id: assignId });
    };
    return {
      ...super.actions(),
      upload: (context, payload) => {
        console.log(context.getters);
        const storageRef = storage()
          .ref()
          .child(`/attendees/${payload.file.name}`);

        storageRef.put(payload.file).then((snapshot) => {
          console.log('File successfully uploaded');
        });
      },
      init: async (context, id) => {
        console.log(id);
        if (id) {
          await getAndSet(context, id);
          return context.getters.current;
        }
        if (context.getters.current !== undefined) {
          return context.getters.current;
        }

        return context.getters.current;
      },

      create: async (context, payload) => {
        const { data } = payload;

        context.commit('setLoading', true);

        const validator = new Schema(this.schema);
        await validator.validate(data).catch((error) => {
          console.log(error);
          const e = Object.assign(error, {
            message: `${this.name} validation error`,
          });
          throw e;
        });

        return axios.post(`${process.env.VUE_APP_BASE_URL}/event`, data).then((res) => {
          console.log(res);
          const doc = res.data.data.event;
          context.commit('addItem', doc);
          context.commit('setLoading', false);
          console.log('created');
          return doc;
        });
      },

    };
  }
}

export default new EventModule(options).getModule();
/* eslint-enable max-classes-per-file */
