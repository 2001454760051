import BaseModule from '@/store/baseFireVuex';
/*
import axios from 'axios';
*/

/* eslint-disable max-classes-per-file */
import * as fb from '@/firebase';
import { db } from '../../firebase';

const schema = {
  text: { type: 'string', required: true },
  bucket: { type: 'string', required: true },
  userName: { type: 'string', required: true },
  location: { type: 'string', required: false },
  isApproved: { type: 'boolean', required: true, default: false },
  isAnswered: { type: 'boolean', required: true, default: false },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'Question',
  getCollectionPath: (context) => {
    const { rootState } = context;
    return `/event/${rootState.event.current}/question`;
  },
  schema,
};

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
class QuestionModule extends BaseModule {
  actions() {
    return {
      ...super.actions(),
      getQnqSettings: async (context, payload) => {
        // console.log(`/event/${context.rootState.event.current}/settings/qnaSettings/qnaModeration`);
        const collection = fb.db
          .collection('event')
          .doc(context.rootState.event.current)
          .collection('settings')
          .doc('qnaSettings');

        return collection
          .get()
          .then((res) => res.data())
          .catch((error) => {
            console.log(error);
            const e = Object.assign(error, {
              message: `Error finding ${this.name} list`,
            });
            throw e;
          });
      },
      updateAll: async (context, payload) => {
        const batch = db.batch();
        payload.data.items.forEach((v) => {
          const docRef = db
            .collection(this.getCollectionPath(context))
            .doc(v.id);
          batch.set(docRef, { ...v, ...payload.data.change });
        });
        batch.commit();
      },
      deleteAll: async (context, payload) => {
        const batch = db.batch();
        payload.data.items.forEach((v) => {
          const docRef = db
            .collection(this.getCollectionPath(context))
            .doc(v.id);
          batch.delete(docRef);
        });
        batch.commit();
      },
    };
  }
}

export default new QuestionModule(options).getModule();
/* eslint-enable max-classes-per-file */
