import Vue from 'vue';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import App from './App.vue';
import store from './store';
import 'bootstrap-icons/font/bootstrap-icons.css';

Vue.config.productionTip = false;
Vue.use(Buefy);
new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
