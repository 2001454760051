<template>
  <div class="container">
    <b-navbar :centered="true">
      <template #brand>
        <b-navbar-item tag="div">
          <img src="https://cloud.livecasthq.com/img/logo@2x.png" alt="Livecast" />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="div">
          {{ eventTitle }}
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item v-show="isModerationSession && selectedMenu === 'trash'" tag="div">
          <div class="buttons mr-3">
            <a @click="$refs.questionsContainer.deleteAll({ bucket: 'inbox', isApproved: false })"
                class="button is-light">
              Empty Trash
            </a>
          </div>
        </b-navbar-item>
        <b-navbar-item v-show="isModerationSession" tag="div">
          <div class="buttons mr-3">
            <a @click="isNewQuestionModalActive = true" class="button is-light">
              Send a Question
            </a>
          </div>
        </b-navbar-item>
        <b-navbar-item v-show="isModerationSession" tag="div">
          <div class="buttons mr-3">
            <a @click="exportCall()"
               class="button is-light">
              Export
            </a>
          </div>
        </b-navbar-item>
        <b-navbar-item v-show="isModerationSession" tag="div">
          <div class="buttons mr-3">
            <a @click="isModerationSession = false" class="button is-light">
              Logout
            </a>
          </div>
        </b-navbar-item>
        <b-navbar-item v-if="!isModerationSession" tag="div">
          <div class="buttons mr-3">
            <a
              @click="isModerationModalActive = true"
              class="button is-medium is-primary"
            >
              Moderation
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="navbar-divider"></div>
    <div class="columns">
      <div class="column is-one-fifth">
        <b-menu>
        <b-menu-list>
          <b-menu-item :active="selectedMenu === 'approved'"
                       @click="selectedMenu = 'approved'"
                       type="is-warning is-light"
                       class="ml-5 has-text-weight-bold">

            <template #label>
              <div
                  class="has-text-centered"
                  style="width: 100%"
                  outlined>
                Approved
                <span class="tag is-rounded ml-2 is-small is-light body">
                  {{
                  qnaModeration ? questions.filter((v) => v.isApproved == true).length
                  : questions.filter((v) => v.bucket !== 'trash').length}}
                </span>
              </div>
            </template>

          </b-menu-item>
        </b-menu-list>
        <div class="dropdown-divider"></div>
          <template v-if="isModerationSession">
            <b-menu-list>
              <b-menu-item
                :active="selectedMenu === 'inbox'"
                @click="selectedMenu = 'inbox'"
                class="ml-5"
              >
                <template #label>
                  Inbox
                  <span class="ml-2 tag is-rounded is-small is-light body">
                    {{ questions.filter((v) => v.bucket == 'inbox').length }}
                  </span>
                </template>
              </b-menu-item>
              <b-menu-item
                :active="selectedMenu === 'archive'"
                @click="selectedMenu = 'archive'"
                class="ml-5"
              >
                <template #label>
                  Archive
                  <span class="ml-2 tag is-rounded is-small is-light body">
                    {{ questions.filter((v) => v.bucket == 'archive').length }}
                  </span>
                </template>
              </b-menu-item>
              <b-menu-item
                :active="selectedMenu === 'trash'"
                @click="selectedMenu = 'trash'"
                class="ml-5"
              >
                <template #label>
                  Trash
                  <span class="ml-2 tag is-rounded is-small is-light body">
                    {{ questions.filter((v) => v.bucket == 'trash').length }}
                  </span>
                </template>
              </b-menu-item>
            </b-menu-list>
          </template>
        </b-menu>
      </div>
      <div class="column">
        <questions-container
          ref="questionsContainer"
          :buttons-type="
            selectedMenu === 'approved'
              ? 'hide'
              : selectedMenu === 'inbox'
              ? 'actions'
              : 'undo'
          "
          :data="filterQuestions()"
          :isModerationSession="isModerationSession"
        >
        </questions-container>
      </div>
    </div>
    <b-modal
      v-model="isNewQuestionModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      @close="clearForm()"
      aria-modal
    >
      <template #default="props">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Send a question</p>
            <button type="button" class="delete" @click="props.close()" />
          </header>
          <section class="modal-card-body">
            <div class="columns mt-1">
              <div class="column is-half">
                <b-field label="Name">
                  <b-input v-model="form.userName"></b-input>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field label="Location">
                  <b-input v-model="form.location"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns mt-1">
              <div class="column is-full">
                <b-field label="Question">
                  <b-input v-model="form.text" type="textarea"></b-input>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button label="Close" @click="props.close()" />
            <b-button label="Send" @click="submitQuestion()" type="is-primary" />
          </footer>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="isModerationModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      @close="isModerationModalActive = false"
      aria-modal
    >
      <template #default="props">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Moderation</p>
            <button type="button" class="delete" @click="props.close()" />
          </header>
          <section class="modal-card-body moderation-body">
            <div class="columns mt-1">
              <div class="column is-full">
                <b-field label="Password">
                  <b-input v-model="moderationPassword"></b-input>
                </b-field>
              </div>
            </div>
          </section>
          <footer style="justify-content: flex-end;" class="modal-card-foot">
            <b-button label="Login" @click="moderationLogin()" type="is-primary" />
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import QuestionsContainer from './components/QuestionsContainer.vue';

export default {
  name: 'App',
  components: {
    QuestionsContainer,
  },
  data() {
    return {
      selectedMenu: 'approved',
      eventTitle: '',
      // questions: [],
      isNewQuestionModalActive: false,
      isModerationModalActive: false,
      moderationPassword: '',
      isModerationSession: false,
      form: {
        userName: '',
        location: '',
        text: '',
      },
      qnaModeration: true,
    };
  },
  computed: {
    ...mapGetters({
      questions: 'question/list',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    ...mapActions({
      setCurrentEvent: 'event/setCurrent',
      findQuestions: 'question/find',
      createNewQuestion: 'question/save',
      getEvent: 'event/find',
      getModerationSettings: 'question/getQnqSettings',
    }),
    exportCall() {
      if (this.$refs.questionsContainer) {
        this.$refs.questionsContainer.exportCSV();
      }
    },
    filterQuestions() {
      if (this.qnaModeration) {
        switch (this.selectedMenu) {
          case 'inbox':
            return this.questions.filter((v) => v.bucket === 'inbox');
          case 'approved':
            return this.questions.filter((v) => v.isApproved === true);
          case 'archive':
            return this.questions.filter((v) => v.bucket === 'archive');
          case 'trash':
            return this.questions.filter((v) => v.bucket === 'trash');
          default:
            return [];
        }
      } else {
        switch (this.selectedMenu) {
          case 'inbox':
            return this.questions.filter((v) => v.bucket === 'inbox');
          case 'approved':
            return this.questions.filter((v) => v.bucket !== 'trash');
          case 'archive':
            return this.questions.filter((v) => v.bucket === 'archive');
          case 'trash':
            return this.questions.filter((v) => v.bucket === 'trash');
          default:
            return [];
        }
      }
    },
    clearForm() {
      this.form.userName = '';
      this.form.location = '';
      this.form.text = '';
    },
    submitQuestion() {
      const self = this;
      this.createNewQuestion({
        data: {
          isApproved: false,
          isAnswered: false,
          bucket: 'inbox',
          ...this.form,
        },
      })
        .then((res) => {
          if (res) {
            self.$buefy.notification.open({
              message: 'New Question Added',
              type: 'is-success',
            });
            self.isNewQuestionModalActive = false;
            self.clearForm();
          }
        })
        .catch((err) => {
          self.$buefy.notification.open({
            message: err.message,
            type: 'is-danger',
          });
        });
    },
    getQuestions(eventId) {
      const self = this;
      this.setCurrentEvent({
        id: eventId,
      });

      this.getModerationSettings().then((res) => {
        self.qnaModeration = res.qnaModeration;
      }).catch(() => {
        self.$buefy.notification.open({
          message: 'No Event Found',
          type: 'is-danger',
        });
      });

      this.findQuestions({
        path: '',
        subscribe: true,
        order: { field: 'createdAt' },
      });
    },
    moderationLogin() {
      if (this.currentEvent.id === this.moderationPassword) {
        this.isModerationSession = true;
        this.isModerationModalActive = false;

        sessionStorage.setItem('moderationLogin', true);
        this.$buefy.notification.open({
          message: 'Moderation Access Granted',
          type: 'is-success',
        });
      } else {
        this.$buefy.notification.open({
          message: 'Moderation Access Denied',
          type: 'is-danger',
        });
      }
    },
  },
  created() {
    const self = this;
    const eventId = window.location.pathname.substring(
      1,
      window.location.pathname.length,
    );

    if (sessionStorage.moderationLogin) {
      this.isModerationSession = true;
    }

    this.getEvent({ id: eventId })
      .then((res) => {
        self.eventTitle = res.title;
        self.getQuestions(eventId);
      })
      .catch(() => {
        self.$buefy.notification.open({
          message: 'No Event Found',
          type: 'is-danger',
        });
      });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.moderation-body {
  min-width: 425px !important;
  padding-top: 0px;
}
</style>
