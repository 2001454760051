import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/user';
import account from '@/store/modules/account';
import event from '@/store/modules/event';
import question from '@/store/modules/question';
import registration from '@/store/modules/registration';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    auth,
    event,
    question,
    registration,
  },
});
