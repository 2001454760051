<template>
  <div class="question-container">
    <template v-if="data.length == 0">
      <div class="notification has-text-weight-semibold has-text-centered">
        We don't have any question here
      </div>
    </template>
    <template v-else>
      <div v-for="item in data" :key="item.id" class="box mb-0">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ item.userName }}</strong>
                <small class="mx-2">--</small>
                <small>{{ dateTimeFormatter(item.createdAt) }}</small>
                <br />
                {{ item.text }}
              </p>
            </div>
          </div>
          <div v-if="buttonsType == 'actions'" class="media-right">
            <a
              :class="[
                item.bucket !== 'inbox' ? 'pointer-event-none' : '',
                item.isApproved ? 'is-success has-text-success' : '',
              ]"
              @click="updateQuestion(item, { isApproved: !item.isApproved })"
              class="mx-2"
            >
              <b-icon
                v-if="item.isApproved"
                class="title"
                type="is-success"
                icon="bi bi-check2-square"
                size="is-small"
              >
              </b-icon>
              <b-icon
                v-else
                class="title"
                icon="bi bi-check2-square"
                size="is-small"
              >
              </b-icon>
              Approve
            </a>

            <a
              @click="
                updateQuestion(item, {
                  bucket: item.bucket === 'archive' ? 'inbox' : 'archive',
                })
              "
              :class="[
                (item.bucket !== 'inbox' && item.bucket !== 'archive') ||
                item.isApproved
                  ? 'pointer-event-none'
                  : '',
                item.bucket === 'archive' ? 'is-success  has-text-success' : '',
              ]"
              class="mx-2"
            >
              <b-icon
                v-if="item.bucket === 'archive'"
                class="title"
                type="is-success"
                icon="bi bi-arrow-down-square-fill"
                size="is-small"
              >
              </b-icon>
              <b-icon
                v-else
                class="title"
                icon="bi bi-arrow-down-square-fill"
                size="is-small"
              >
              </b-icon>
              Archive
            </a>

            <a
              @click="
                updateQuestion(item, {
                  bucket: item.bucket === 'trash' ? 'inbox' : 'trash',
                })
              "
              :class="[
                (item.bucket !== 'inbox' && item.bucket !== 'trash') ||
                item.isApproved
                  ? 'pointer-event-none'
                  : '',
                item.bucket === 'trash' ? 'is-success  has-text-success' : '',
              ]"
              class="mx-2"
            >
              <b-icon
                tag="a"
                v-if="item.bucket === 'trash'"
                type="is-success"
                class="title"
                icon="bi bi-trash"
                size="is-small"
              >
              </b-icon>
              <b-icon
                tag="a"
                v-else
                class="title"
                icon="bi bi-trash"
                size="is-small"
              >
              </b-icon>
              Delete
            </a>
          </div>
          <div v-if="buttonsType == 'undo'" class="media-right">
            <a
              @click="
                updateQuestion(item, { bucket: 'inbox', isApproved: false })
              "
              class="mx-2"
            >
              <b-icon
                class="title"
                icon="bi bi-arrow-clockwise"
                size="is-small"
              >
              </b-icon>
              Undo
            </a>
          </div>
        </article>
      </div>
    </template>
    <div v-if="isModerationSession" style="float: right" class="btn-group mt-3">
      <b-button
        class="primary px-5"
        style="padding: 0.5rem"
        v-on:click="exportCSV"
      >
        Export
      </b-button>
    </div>
    <!--
    <b-pagination
        :total="total"
        v-model="current"
        :range-before="rangeBefore"
        :range-after="rangeAfter"
        :order="order"
        :size="size"
        :simple="isSimple"
        :rounded="isRounded"
        :per-page="perPage"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">
    </b-pagination>
    -->
  </div>
</template>
<style>
@import "../assets/css/QuestionContainer.css";
</style>
<script>
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */

import moment from 'moment';
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'QuestionsContainer',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    buttonsType: {
      type: String,
      default: 'actions',
    },
    isModerationSession: { type: Boolean, default: false },
  },
  data() {
    return {
      // total: 200,
      // current: 10,
      // perPage: 10,
      // rangeBefore: 3,
      // rangeAfter: 1,
      // order: '',
      // size: '',
      // isSimple: false,
      // isRounded: false,
    };
  },
  methods: {
    ...mapActions({
      saveQuestionAction: 'question/update',
      deleteTrashAll: 'question/deleteAll',
    }),
    dateTimeFormatter(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY HH:mm:SS');
    },
    deleteAll(change) {
      const data = { items: this.data, change };
      this.deleteTrashAll({
        data,
      }).then((res) => {
        console.log('update Response', res);
      });
    },
    updateQuestion(current, change) {
      const data = { ...current, ...change };
      this.saveQuestionAction({
        data,
      }).then((res) => {
        console.log('update Response', res);
      });
    },
    exportCSV() {
      let csv = null;
      const data = [];

      if (this.data.length > 0) {
        this.data.forEach((q) => {
          const objWithPickedProps = _.pick(q, ['location', 'text', 'userName', 'createdAt']);
          const ordered = Object.keys(objWithPickedProps)
            .sort()
            .reduce((obj, key) => {
              obj[key] = q[key];

              return obj;
            }, {});

          data.push(ordered);
        });

        const fields = [];

        Object.keys(data[0]).forEach((key) => {
          fields.push(_.startCase(key));
        });

        csv = _.join(fields, ';');
        csv += '\n';
      }

      data.forEach((row) => {
        console.log(row);
        csv += _.values(row).join(';');
        csv += '\n';
      });

      const hiddenElement = document.createElement('a');

      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'questions.csv';
      hiddenElement.click();
    },
  },
};
</script>
