var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-container"},[(_vm.data.length == 0)?[_c('div',{staticClass:"notification has-text-weight-semibold has-text-centered"},[_vm._v(" We don't have any question here ")])]:_vm._l((_vm.data),function(item){return _c('div',{key:item.id,staticClass:"box mb-0"},[_c('article',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('p',[_c('strong',[_vm._v(_vm._s(item.userName))]),_c('small',{staticClass:"mx-2"},[_vm._v("--")]),_c('small',[_vm._v(_vm._s(_vm.dateTimeFormatter(item.createdAt)))]),_c('br'),_vm._v(" "+_vm._s(item.text)+" ")])])]),(_vm.buttonsType == 'actions')?_c('div',{staticClass:"media-right"},[_c('a',{staticClass:"mx-2",class:[
              item.bucket !== 'inbox' ? 'pointer-event-none' : '',
              item.isApproved ? 'is-success has-text-success' : '' ],on:{"click":function($event){return _vm.updateQuestion(item, { isApproved: !item.isApproved })}}},[(item.isApproved)?_c('b-icon',{staticClass:"title",attrs:{"type":"is-success","icon":"bi bi-check2-square","size":"is-small"}}):_c('b-icon',{staticClass:"title",attrs:{"icon":"bi bi-check2-square","size":"is-small"}}),_vm._v(" Approve ")],1),_c('a',{staticClass:"mx-2",class:[
              (item.bucket !== 'inbox' && item.bucket !== 'archive') ||
              item.isApproved
                ? 'pointer-event-none'
                : '',
              item.bucket === 'archive' ? 'is-success  has-text-success' : '' ],on:{"click":function($event){return _vm.updateQuestion(item, {
                bucket: item.bucket === 'archive' ? 'inbox' : 'archive',
              })}}},[(item.bucket === 'archive')?_c('b-icon',{staticClass:"title",attrs:{"type":"is-success","icon":"bi bi-arrow-down-square-fill","size":"is-small"}}):_c('b-icon',{staticClass:"title",attrs:{"icon":"bi bi-arrow-down-square-fill","size":"is-small"}}),_vm._v(" Archive ")],1),_c('a',{staticClass:"mx-2",class:[
              (item.bucket !== 'inbox' && item.bucket !== 'trash') ||
              item.isApproved
                ? 'pointer-event-none'
                : '',
              item.bucket === 'trash' ? 'is-success  has-text-success' : '' ],on:{"click":function($event){return _vm.updateQuestion(item, {
                bucket: item.bucket === 'trash' ? 'inbox' : 'trash',
              })}}},[(item.bucket === 'trash')?_c('b-icon',{staticClass:"title",attrs:{"tag":"a","type":"is-success","icon":"bi bi-trash","size":"is-small"}}):_c('b-icon',{staticClass:"title",attrs:{"tag":"a","icon":"bi bi-trash","size":"is-small"}}),_vm._v(" Delete ")],1)]):_vm._e(),(_vm.buttonsType == 'undo')?_c('div',{staticClass:"media-right"},[_c('a',{staticClass:"mx-2",on:{"click":function($event){return _vm.updateQuestion(item, { bucket: 'inbox', isApproved: false })}}},[_c('b-icon',{staticClass:"title",attrs:{"icon":"bi bi-arrow-clockwise","size":"is-small"}}),_vm._v(" Undo ")],1)]):_vm._e()])])}),(_vm.isModerationSession)?_c('div',{staticClass:"btn-group mt-3",staticStyle:{"float":"right"}},[_c('b-button',{staticClass:"primary px-5",staticStyle:{"padding":"0.5rem"},on:{"click":_vm.exportCSV}},[_vm._v(" Export ")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }