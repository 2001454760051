import BaseModule from '@/store/baseFireVuex';

/* eslint-disable max-classes-per-file */

const schema = {
  name: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'Account',
  getCollectionPath: (payload) => '/account',
  schema,
};

class AccountModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
      currentPath: (state) => `account/${state.current}`,
    };
  }

  actions() {
    const getAndSet = async (context, assignId) => {
      let ac = null;
      console.log('get - set Acccount');
      await context.dispatch('get', { id: assignId }).then((account) => {
        console.log(account);
        ac = account;
      }).catch((err) => {
        console.log(err);
      });
      await context.dispatch('setCurrent', { id: assignId }).then(() => {
      });
      localStorage.setItem('mgnt_ac', assignId);
    };

    return {
      ...super.actions(),
      init: async (context, id) => {
        if (id) {
          await getAndSet(context, id);
          return context.getters.current;
        }
        if (context.getters.current !== undefined) {
          return context.getters.current;
        }
        if (localStorage.getItem('mgnt_ac')) {
          await getAndSet(context, localStorage.getItem('mgnt_ac'));
          return context.getters.current;
        }

        if (context.state.current) {
          await getAndSet(context, context.getters.current);
          return context.getters.current;
        }

        return context.getters.current;
      },
    };
  }
}

export default new AccountModule(options).getModule();
/* eslint-enable max-classes-per-file */
