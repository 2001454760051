import BaseModule from '@/store/baseFireVuex';
/*
import axios from 'axios';
*/

/* eslint-disable max-classes-per-file */

const schema = {
  _id: {
    type: 'string',
    required: true,
  },
  uid: {
    type: 'string',
    required: true,
  },
  name: {
    type: 'string',
    required: true,
  },
  location: {
    type: 'string',
    required: true,
  },
  device: {
    type: 'string',
    required: true,
  },
  info: {
    type: 'string',
    required: true,
  },
  createdAt: {
    type: 'date',
    required: true,
  },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'Registration',
  getCollectionPath: (context) => {
    const { rootState } = context;
    return `/event/${rootState.event.current}/registration`;
  },
  schema,
};

/* eslint-disable no-unused-vars */
class RegistrationModule extends BaseModule {}

export default new RegistrationModule(options).getModule();
/* eslint-enable max-classes-per-file */
