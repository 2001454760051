/* eslint-disable object-curly-newline */
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyAznmc7VW5st-6cpLLsDfvlZ_YHmtkr0dI',
  authDomain: 'magnetlive3.firebaseapp.com',
  databaseURL: 'https://magnetlive3-317fd.europe-west1.firebasedatabase.app/',
  projectId: 'magnetlive3',
  storageBucket: 'magnetlive3.appspot.com',
  messagingSenderId: '544101773540',
  appId: '1:544101773540:web:e3e9341467e7190cc73cf1',
  measurementId: 'G-VSQTX1L0LS',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const { storage, database } = firebase;

// lokalde emulatore yönlendir
// if (window.location.hostname === 'localhost') {
//   db.settings({
//     host: 'localhost:1340',
//     ssl: false,
//   });
//   database().useEmulator('localhost', 9003);
//   // auth.useEmulator('http://localhost:5000');
// }

export { firebase, db, auth, storage, database };
