import { auth } from '@/firebase';

const getters = {};

const actions = {
  /* eslint-disable no-unused-vars */
  async login({ dispatch }, form) {
    // sign user in
    console.log(form);
    await auth.signInWithEmailAndPassword(form.email, form.password);

    // fetch user profile and set in state
    // dispatch('fetchUserProfile', user)
  },
  async logout({ dispatch }) {
    // sign user in
    await auth.signOut();

    // fetch user profile and set in state
    // dispatch('fetchUserProfile', user)
  },
  /* eslint-enable no-unused-vars */
  async fetchUserProfile() {
    // fetch user profile
    // await fb.usersCollection.doc(user.uid).get();

    // set user profile in state
    // commit('setUserProfile', userProfile.data())

    // change route to dashboard
    // router.push('/');
  },
};

const mutations = {
  setUserProfile(state, val) {
    state.userProfile = val;
  },

};
const state = {
  userProfile: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
